<template>
  <div>
    <h1 class="ui-title-page">RealHub Testimonials</h1>
    <div class="triagl triagl-btm"></div>
    <section class="section-area section_slider-reviews bg-gray section-testimoials" id="testimonials">
      <div class="container">
        <div class="row">
          <div class="col-xs-12">
            <h2 class="ui-title-block">HELP US SERVE YOU BETTER</h2>
            <div class="ui-subtitle-block">LEAVE A REVIEW</div>
            <div class="border-color border-color_center"></div>
          </div>
        </div>
        <div class="card mt-5">
          <form @submit.prevent="submit" v-if="! loading">
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label>Your Name</label>
                  <input type="text" placeholder="Full Name" class="form-control" v-model="user.name" required>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label>Your Email</label>
                  <input type="text" placeholder="Full Email" class="form-control" v-model="user.email" required>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label>Your Phone Number</label>
                  <input type="text" placeholder="Full Phone Number" class="form-control" v-model="user.phone_number" required>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label>Rate Your Experience</label>
                  <div class="form-control">
                    <input-rating v-model="user.rating" />
                  </div>
                </div>
              </div>
              <div class="col-md-12">
                <div class="form-group">
                  <label>Your Comments</label>
                  <textarea class="form-control" rows="6" placeholder="Your Comments" v-model="user.content" required></textarea>
                </div>
              </div>
              <div class="col-md-12">
                <div class="form-group">
                  <button class="btn btn-primary"><i class="lni lni-save"></i> Save</button>
                </div>
              </div>
            </div>
          </form>
          <loading v-if="loading" />
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  mounted() {
    if(! this.$auth.hasToken()) {
      window.location.href = '/testimonials'
    }

    this.user.name = this.$store.getters.getAuthUser.name
    this.user.email = this.$store.getters.getAuthUser.email
    this.user.phone_number = this.$store.getters.getAuthUser.phone_number
  },

  data() {
    return {
      user: {},
      loading: false
    }
  },

  methods: {
    submit() {
      this.loading = true

      this.$axios.post('/api/v1/testimonials', this.user).then(() => {
        this.loading = false
        
        this.$swal('success', 'Your review has been receiced.', 'success').then(() => {
          this.$router.push({ name: 'testimonials' })
        })
      })
    }
  }
}
</script>